export const ROUTES = {
  home: { path: '/' },
  blog: { path: '/blog' },
  blogPosts: { path: '/blog/posts' },
  /*pressKit: { path: '/press-kit' },*/
  leadership: { path: '/leadership' },
  products: { path: '/products' },
  claims: { path: '/claims' },
  contact: { path: '/contact' },
  agents: { path: '/agents' },
  newAgents: { path: '/new-agents' },
  fnolTTIC: { path: `/fnol/?companyCode=ttic` },
  fnolHCPC: { path: `/fnol/?companyCode=hcpc` },
  service: { path: '/terms-of-service' },
  privacy: { path: '/privacy-notice' },
  accessibility: { path: '/accessibility' },
  careers: { path: '/careers' },
  pressKit: { path: '/press-kit' },
  agencyToolkit: { path: '/toolkit' }
};
